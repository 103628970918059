import * as React from 'react';
import { Link } from 'react-router-dom';

interface IReferenceProjectProps {
  slug: string;
  image: string;
  title: string;
  tags: string;
  wide?: boolean;
}

const ReferenceProject: React.FunctionComponent<IReferenceProjectProps> = ({
  slug,
  image,
  title,
  tags,
  wide = false,
}) => {
  let col_className = wide ? 'col-lg-8 col-12' : 'col-lg-4 col-12';

  let p_className = wide
    ? 'projects-thumb projects-thumb-large'
    : 'projects-thumb projects-thumb-small';

  return (
    <div className={col_className}>
      <div className={p_className}>
        <Link to={`project/${slug}`}>
          <img src={image} className="img-fluid projects-image" alt="" />
          <div className="projects-info">
            <div className="projects-title-wrap">
              <small className="projects-small-title">{tags}</small>
              <h2 className="projects-title">{title}</h2>
            </div>
            <div className="projects-btn-wrap mt-4">
              <span className="custom-btn btn">
                <i className="bi-arrow-right" />
              </span>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default ReferenceProject;
