import React from 'react';
import Navigation from './Navigation';
import Home from './Home';
import ProjectDetails from './ProjectDetails';
import { Route, Routes, useNavigate } from 'react-router-dom';
import ScrollToHashElement from './Scroll';
import { relativeURL } from './Utils';

function App() {
  // Super simple project detail navigation:

  return (
    <>
      <ScrollToHashElement />
      <Navigation></Navigation>

      <Routes>
        <Route path={relativeURL('/')} element={<Home />}></Route>
        <Route
          path={relativeURL('/project/:slug')}
          element={<ProjectDetails />}
        ></Route>
        <Route
          path={relativeURL('/project/:slug/*')}
          element={<ProjectDetails />}
        ></Route>
        <Route path="*" element={<NoMatch />}></Route>
        <Route path={relativeURL('/project/*/*')} element={<NoMatch />}></Route>
      </Routes>
    </>
  );
}

export default App;

const NoMatch = () => {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  return (
    <>
      <section className="section-padding">
        <div className="container">
          <div onClick={goBack}>
            <span className="detail-back-btn btn">
              <i className="bi-arrow-left" /> Tillbaks
            </span>
          </div>
          <br></br>
          <br></br>
          <h2>Det här är inte sidan du letar efter ...</h2>
        </div>
      </section>
    </>
  );
};
