import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { hydrate } from 'react-dom';

// temporary
const api_key = 'a3bae4ca104eaf29eaa88eefb71e0b04832df764a6923250';
const api_url = 'https://d14e3d79zlycrp.cloudfront.net/cms/read/sv-SE';

const client = new ApolloClient({
  uri: api_url,
  cache: new InMemoryCache(),
  headers: {
    Authorization: `Bearer ${api_key}`,
  },
});

const rootElement = document.getElementById('root') as HTMLElement;
const root = ReactDOM.createRoot(rootElement);

if (rootElement.hasChildNodes()) {
  hydrate(
    <React.StrictMode>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ApolloProvider>
    </React.StrictMode>,
    rootElement
  );
} else {
  root.render(
    <React.StrictMode>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ApolloProvider>
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
