import * as React from 'react';
import { relativeURL } from '../Utils';

interface IAboutSectionProps {}

const AboutSection: React.FunctionComponent<IAboutSectionProps> = (props) => {
  return (
    <section className="about section-padding" id="about">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-12 mb-5 mb-lg-0">
            <div className="about-image-wrap h-100">
              <img
                src={relativeURL(
                  '/images/projects/elena-baidak-pz69kY0UQuQ-unsplash.jpg'
                )}
                className="img-fluid about-image"
                alt=""
              />
              <div className="about-image-info">
                <h4 className="text-white">Vi ❤️ produktutveckling ... </h4>
                <p className="text-white mb-0">
                  och är gärna med på resan att förfina och utveckla din vision
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-12 d-flex flex-column">
            <div className="about-thumb bg-white shadow-lg">
              <div className="about-info">
                <small className="small-title">
                  OM <strong className="text-warning">OSS</strong>
                </small>
                <h2 className="mb-3">Din utvecklingspartner</h2>
                <h5 className="mb-3">Förverkligar visioner sedan 2014</h5>
                <p>
                  Vi är ett gäng passionerade utvecklare som brinner för
                  applikationsutveckling. Vi har lång erfarenhet av att bygga
                  system och applikationer. Allt ifrån mobilappar till desktop
                  och web. Vi tar gärna ett helhetsansvar och systemerar och
                  bygger end-to-end, backend till frontend. Vi ser till att din
                  lösning är skalbar och tar även ansvar för upprättande och
                  underhåll av infrastruktur i AWS, Azure eller GCP.
                </p>
                <p />
              </div>
            </div>
            <div className="row h-100">
              <div className="col-lg-12 col-12">
                <div className="about-thumb d-flex flex-column justify-content-center bg-danger mb-lg-0 h-100">
                  <div className="about-info">
                    <h5 className="text-white mb-4">
                      Vi gör verklighet av din idé
                    </h5>
                    <a
                      className="custom-btn btn custom-bg-primary"
                      href="#services"
                    >
                      Anlita oss
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
