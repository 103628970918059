import { gql } from '@apollo/client';
import { PayloadLexicalReactRendererContent } from '@atelier-disko/payload-lexical-react-renderer';
//Adding a query list to the FlatPage component
export const QueryListProjects = gql`
  query ListProjects {
    listReferenceProjects(sort: order_ASC) {
      data {
        entryId
        slug
        title
        cardTags
        cardImage
        displayWide
      }
    }
  }
`;

export const QueryGetProject = gql`
  query getReferenceProjectBySlug($slug: String!) {
    getReferenceProject(where: { slug: $slug }) {
      data {
        entryId
        slug
        title
        articleImage
        article
        cardTags
        lastPublishedOn
        lastPublishedBy {
          displayName
        }
      }
    }
  }
`;

export const QueryPostSlack = gql`
  query PostSlack($name: String!, $email: String!, $message: String!) {
    postSlack(name: $name, email: $email, message: $message)
  }
`;

export interface ReferenceProjectItem {
  entryId: string;
  slug: string;
  title: string;
  cardImage: string;
  cardTags: string;
  displayWide: boolean;
}

export interface ReferenceProjectArticle {
  entryId: string;
  slug: string;
  title: string;
  articleImage: string;
  article: PayloadLexicalReactRendererContent;
  cardTags: string;
  lastPublishedOn: string;
}
