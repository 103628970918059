import * as React from 'react';
import { useState } from 'react';
import { QueryPostSlack } from '../graphql/Queries';
import { useApolloClient } from '@apollo/client';

interface IContactSectionProps {}

const ContactSection: React.FunctionComponent<IContactSectionProps> = (
  props
) => {
  const client = useApolloClient();

  const nameRef = React.useRef<HTMLInputElement>(null);
  const emailRef = React.useRef<HTMLInputElement>(null);
  const messageRef = React.useRef<HTMLTextAreaElement>(null);

  const [show, setShow] = useState(false);
  const [sending, setSending] = useState(false);

  const closeModal = () => {
    setSending(false);
    setShow(false);
  };

  const post = async (event: any) => {
    const name = nameRef.current?.value;
    const email = emailRef.current?.value;
    const message = messageRef.current?.value;

    setSending(true);
    setShow(true);

    // TODO, error handling. If errored, show the it failed and don't clear the fields
    await client.query({
      query: QueryPostSlack,
      variables: {
        name,
        email,
        message,
      },
    });

    if (nameRef.current) {
      nameRef.current.value = '';
    }
    if (emailRef.current) {
      emailRef.current.value = '';
    }
    if (messageRef.current) {
      messageRef.current.value = '';
    }

    setSending(false);
  };

  const buildForm = () => {
    return (
      <div className="custom-form contact-form">
        <small className="small-title">Kontakt</small>
        <h2 className="mb-5">Säg hej till oss 👋</h2>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-12">
            <input
              ref={nameRef}
              type="text"
              name="name"
              id="name"
              className="form-control"
              placeholder="Ditt Namn"
              required={false}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <input
              ref={emailRef}
              type="email"
              name="email"
              id="email"
              pattern="[^ @]*@[^ @]*"
              className="form-control"
              placeholder="din@email.se"
              required={true}
            />
          </div>
          <div className="col-12">
            <textarea
              ref={messageRef}
              className="form-control"
              rows={7}
              id="message"
              name="message"
              placeholder="Meddelande"
            />
            <button className="form-control" onClick={post}>
              Skickas
            </button>
          </div>
        </div>
      </div>
    );
  };

  const buildSending = () => {
    return (
      <div className="custom-form contact-form">
        <small className="small-title">Kontakt</small>
        <h2 className="mb-5">Skickar</h2>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-12">
            Skickar ditt meddalande ...
          </div>

          <div className="col-12">
            <br></br>
            <br></br>
            <br></br>
            <br></br>
          </div>
        </div>
      </div>
    );
  };

  const buildThanks = () => {
    if (sending) return buildSending();
    return (
      <div className="custom-form contact-form">
        <small className="small-title">Kontakt</small>
        <h2 className="mb-5">Tack för ditt meddelande 👋</h2>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-12">
            Vi läser ditt meddelande och hör av oss.
          </div>

          <div className="col-12">
            <br></br>
            <br></br>
            <br></br>
            <button className="form-control" onClick={closeModal}>
              Skicka fler meddelanden
            </button>
            <br></br>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <section className="contact" id="contact">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#f9c10b"
            fillOpacity={1}
            d="M0,288L48,272C96,256,192,224,288,197.3C384,171,480,149,576,165.3C672,181,768,235,864,250.7C960,267,1056,245,1152,250.7C1248,256,1344,288,1392,304L1440,320L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
          ></path>
        </svg>
        <div className="contact-container-wrap">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-12">
                {show ? buildThanks() : buildForm()}
              </div>

              <div className="col-lg-6 col-12">
                <div className="contact-thumb">
                  <div className="contact-info bg-white shadow-lg">
                    <h4 className="mb-2">
                      <a href="tel: 08-4455480">
                        <i className="bi-telephone contact-icon me-2" />
                        08-44 55 480
                      </a>
                    </h4>
                    <hr />
                    <h6 className="mb-2">
                      <a href="tel: 070-286 49 49">
                        <i className="bi-telephone contact-icon me-2" />
                        Stefan 070-286 49 49
                      </a>
                    </h6>
                    <h6 className="mb-2">
                      <a href="tel: 070-713 83 11">
                        <i className="bi-telephone contact-icon me-2" />
                        Ola 070-713 83 11
                      </a>
                    </h6>
                    <hr />
                    <h5>
                      <a href="mailto:info@norn.io" className="footer-link">
                        <i className="bi-envelope-fill contact-icon me-2" />
                        info@norn.io
                      </a>
                    </h5>
                    {/* Copy "embed a map" HTML code from any point on Google Maps -> Share Link  */}
                    {/*iframe class="google-map mt-4"
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4063.103580426464!2d-83.0264337484065!3d42.33402597908653!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x883b2cce05ddf4f1%3A0xcc0559eb3fda00c9!2sDetroit%20Riverwalk%2C%20Detroit%2C%20MI!5e1!3m2!1sen!2sus!4v1657814406289!5m2!1sen!2sus"
                      width="100%" height="300" allowfullscreen="" loading="lazy"></iframe*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactSection;
