import {
  PayloadLexicalReactRendererContent,
  defaultElementRenderers,
} from '@atelier-disko/payload-lexical-react-renderer';
import { NornPayloadLexicalReactRenderer } from './NornPayloadLexicalReactRenderer';

interface ImageNode {
  src: string;
  altText: string;
  width: number;
  height: number;
  caption: ImageNodeCaption;
}

interface ImageNodeCaption {
  editorState: PayloadLexicalReactRendererContent;
}

interface IImageRendererProps {
  node: ImageNode;
}

const ImageRenderer: React.FunctionComponent<IImageRendererProps> = ({
  node,
}) => {
  const caption = node.caption.editorState;
  const shoulddisplayCaption = false;

  return (
    <>
      <img
        className="inline-image-wrap"
        src={node.src}
        alt={node.altText}
        width={node.width}
        height={node.height}
      />
      {shoulddisplayCaption ? (
        <NornPayloadLexicalReactRenderer
          content={caption}
          elementRenderers={{
            ...defaultElementRenderers,
            paragraph: (props) => (
              <div
                style={{
                  bottom: -node.height / 2 + 8,
                  left: -node.width,
                  display: 'inline',
                  position: 'relative',
                  padding: 10,
                  backgroundColor: '#f9c10b',
                  borderRadius: 8,
                  fontSize: 'small',
                }}
              >
                {props.children}
              </div>
            ),
          }}
        />
      ) : (
        <></> // No caption
      )}
    </>
  );
};

export default ImageRenderer;
