import * as React from 'react';
import { relativeURL } from '../Utils';
import { Link } from 'react-router-dom';

interface IHeroSectionProps {}

const HeroSection: React.FunctionComponent<IHeroSectionProps> = (props) => {
  return (
    <section className="hero">
      <div className="container-fluid h-100">
        <div className="row h-100">
          <div
            id="carouselExampleCaptions"
            className="carousel carousel-fade hero-carousel slide"
            data-bs-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <div className="container position-relative h-100">
                  <div className="carousel-caption d-flex flex-column justify-content-center">
                    <small className="small-title">
                      Låt oss arbeta tillsammans
                    </small>
                    <h1>
                      Din <span className="text-warning">vision</span>, vår
                      passion
                    </h1>
                    <div className="d-flex align-items-center mt-4">
                      <a className="custom-btn btn" href="#about">
                        Kom igång
                      </a>
                    </div>
                  </div>
                </div>
                <div className="carousel-image-wrap">
                  <img
                    src={relativeURL('/images/slide/frosty.jpg')}
                    className="img-fluid carousel-image"
                    alt=""
                  />
                </div>
              </div>
              <div className="carousel-item">
                <div className="container position-relative h-100">
                  <div className="carousel-caption d-flex flex-column justify-content-center">
                    {/*small class="small-title">Vi ger aldrig upp</small*/}
                    <h1>
                      Kan du fantisera det,{' '}
                      <span className="text-warning">så kan vi bygga det</span>
                    </h1>
                    <div className="d-flex align-items-center mt-4">
                      <Link className="custom-btn btn" to="#about">
                        Utforska möjligheter
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="carousel-image-wrap">
                  <img
                    src={relativeURL('/images/slide/shadows.png')}
                    className="img-fluid carousel-image"
                    alt=""
                  />
                </div>
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                />
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                />
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
