import React, { ReactNode } from 'react';
import { useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { QueryGetProject, ReferenceProjectArticle } from './graphql/Queries';
import {
  NornPayloadLexicalReactRenderer,
  RichTextHasContent,
} from './richtextcomponent/NornPayloadLexicalReactRenderer';

export default function ProjectDetails() {
  const { slug } = useParams();

  const { loading, error, data } = useQuery(QueryGetProject, {
    variables: { slug },
  });

  if (loading) return <LoadingPage></LoadingPage>;
  if (error) return <ErrorPage></ErrorPage>;
  const project: ReferenceProjectArticle = data?.getReferenceProject.data;
  const rtContent = project?.article;
  const title = project?.title;

  if (!title)
    return <DetailPage title="Hittade inget sådant projekt"></DetailPage>;

  return (
    <>
      <DetailPage title={project.title}>
        {RichTextHasContent(rtContent) ? (
          <NornPayloadLexicalReactRenderer content={rtContent} />
        ) : (
          <p>Information om detta projekt kommer snart. </p>
        )}
      </DetailPage>
    </>
  );
}

const ErrorPage = () => {
  return <DetailPage title="Hittade inget sådant projekt" />;
};

const LoadingPage = () => {
  return <DetailPage title="Laddar ..." />;
};

interface IDetailPageProps {
  title: string;
  children?: ReactNode;
}

export const DetailPage: React.FunctionComponent<IDetailPageProps> = ({
  title,
  children,
}) => {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  return (
    <>
      <section className="section-padding">
        <div className="container">
          <div onClick={goBack}>
            <span className="detail-back-btn btn">
              <i className="bi-arrow-left" /> Tillbaks
            </span>
          </div>
          <br></br>
          <br></br>
          <h2>{title}</h2>
          {children}
        </div>
      </section>
    </>
  );
};
