import React from 'react';
import ProjectsSection from './sections/ProjectsSection';
import ContactSection from './sections/ContactSection';
import AboutSection from './sections/AboutSection';
import HeroSection from './sections/HeroSection';
import ServicesSection from './sections/ServicesSection';

export default function Home() {
  return (
    <main>
      <HeroSection />
      <AboutSection />
      <ServicesSection />
      <ProjectsSection />
      <ContactSection />
    </main>
  );
}
