import { Root } from '@atelier-disko/payload-lexical-react-renderer';
import { NornPayloadLexicalReactRenderer } from './NornPayloadLexicalReactRenderer';

interface LinkNode extends Root {
  url: string;
}

interface ILinkRendererProps {
  node: LinkNode;
}

const LinkRenderer: React.FunctionComponent<ILinkRendererProps> = ({
  node,
}) => {
  return (
    <>
      <a className="article" href={node.url} target="_blank">
        <NornPayloadLexicalReactRenderer content={{ root: node }} />
      </a>
    </>
  );
};

export default LinkRenderer;
