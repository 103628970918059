import React from 'react';
import { Link } from 'react-router-dom';
import { relativeURL } from './Utils';

export default function Navigation() {
  return (
    <nav className="navbar navbar-expand-lg bg-white shadow-lg fixed-top">
      <div className="container">
        <Link to={relativeURL('/#')} className="navbar-brand">
          <img
            src={relativeURL('/images/IrReg_Colors_Left_320.png')}
            width={160}
          />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <Link className="nav-link" to={relativeURL('/#')}>
                Hem
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to={relativeURL('/#about')}>
                Om oss
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to={relativeURL('/#services')}>
                Erbjudande
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to={relativeURL('/#projects')}>
                Projekt
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to={relativeURL('/#contact')}>
                Kontakt
              </Link>
            </li>
          </ul>
          <div></div>
        </div>
      </div>
    </nav>
  );
}
