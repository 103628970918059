import React from 'react';
import { useQuery } from '@apollo/client';
import ReferenceProject from './ReferenceProject';
import { QueryListProjects, ReferenceProjectItem } from '../graphql/Queries';

export default function ProjectsSection() {
  const { loading, error, data } = useQuery(QueryListProjects);
  if (loading) return <p className="container">Hämtar aktuella projekt ...</p>;
  if (error)
    return <p className="container">Just nu finns inga projekt att visa</p>;

  const projects: ReferenceProjectItem[] = data.listReferenceProjects.data;

  return (
    <section className="projects section-padding pb-0" id="projects">
      <div className="container">
        <div className="row">
          <div className="col-lg-10 col-12 text-center mx-auto mb-5">
            <small className="small-title">Projekt</small>
            <h2>Några av våra projekt och kunder</h2>
          </div>
          {projects.map((project) => (
            <ReferenceProject
              key={project.entryId}
              slug={project.slug}
              title={project.title}
              image={project.cardImage}
              tags={project.cardTags}
              wide={project.displayWide}
            />
          ))}
        </div>
      </div>
    </section>
  );
}
