import React from 'react';
import {
  PayloadLexicalReactRendererContent,
  PayloadLexicalReactRendererProps,
  TextNode,
  defaultElementRenderers,
  defaultRenderMark,
} from '@atelier-disko/payload-lexical-react-renderer';
import ImageRenderer from './ImageRenderer';
import LinkRenderer from './LinkRenderer';

/// ------------------------
const IS_BOLD = 1;
const IS_ITALIC = 1 << 1;
const IS_STRIKETHROUGH = 1 << 2;
const IS_UNDERLINE = 1 << 3;
const IS_CODE = 1 << 4;
const IS_SUBSCRIPT = 1 << 5;
const IS_SUPERSCRIPT = 1 << 6;
const IS_HIGHLIGHT = 1 << 7;

export function NornPayloadLexicalReactRenderer<
  Blocks extends { [key: string]: any }
>({
  content,
  elementRenderers = defaultElementRenderers,
  renderMark = defaultRenderMark,
  blockRenderers = {},
}: PayloadLexicalReactRendererProps<Blocks>) {
  const renderElement = React.useCallback(
    (node: any, children?: React.ReactNode) => {
      if (!content?.root) return <></>;
      if (!content?.root?.children) return <></>;

      if (!elementRenderers) {
        throw new Error("'elementRenderers' prop not provided.");
      }

      if (node.type === 'link' && node.fields) {
        return elementRenderers.link({
          ...node,
          children,
        });
      }

      if (node.type === 'autolink' && node.fields) {
        return elementRenderers.autolink({
          ...node,
          children,
        });
      }

      if (node.type === 'heading' || node.type === 'heading-element') {
        return elementRenderers.heading({
          ...node,
          children,
        });
      }

      if (node.type === 'paragraph' || node.type === 'paragraph-element') {
        return elementRenderers.paragraph({
          ...node,
          children,
        });
      }

      if (node.type === 'list' || node.type === 'webiny-list') {
        return elementRenderers.list({
          ...node,
          children,
        });
      }

      if (node.type === 'listitem' || node.type === 'webiny-listitem') {
        return elementRenderers.listItem({
          ...node,
          children,
        });
      }

      if (node.type === 'quote' || node.type === 'webiny-quote') {
        return elementRenderers.quote({
          ...node,
          children,
        });
      }

      if (node.type === 'linebreak') {
        return elementRenderers.linebreak();
      }

      if (node.type === 'tab') {
        return elementRenderers.tab();
      }

      if (node.type === 'upload') {
        return elementRenderers.upload(node);
      }

      if (node.type === 'image') {
        return <ImageRenderer node={node}></ImageRenderer>;
      }

      if (node.type === 'link') {
        console.log(node);
        return <LinkRenderer node={node}></LinkRenderer>;
      }

      throw new Error(
        `Något gick fel, hittar inte en rendrerare för '${node.type}'`
      );
    },
    [elementRenderers, content?.root]
  );

  const renderText = React.useCallback(
    (node: TextNode): React.ReactNode | null => {
      if (!renderMark) {
        throw new Error("'renderMark' prop not provided.");
      }

      if (!node.format) {
        return renderMark({
          text: node.text,
        });
      }

      return renderMark({
        text: node.text,
        bold: (node.format & IS_BOLD) > 0,
        italic: (node.format & IS_ITALIC) > 0,
        underline: (node.format & IS_UNDERLINE) > 0,
        strikethrough: (node.format & IS_STRIKETHROUGH) > 0,
        code: (node.format & IS_CODE) > 0,
        subscript: (node.format & IS_SUBSCRIPT) > 0,
        superscript: (node.format & IS_SUPERSCRIPT) > 0,
        highlight: (node.format & IS_HIGHLIGHT) > 0,
      });
    },
    [renderMark]
  );

  const serialize = React.useCallback(
    (children: any[]): React.ReactNode[] | null =>
      children?.map((node, index) => {
        if (node.type === 'text') {
          return (
            <React.Fragment key={index}>{renderText(node)}</React.Fragment>
          );
        }

        if (node.type === 'block') {
          const renderer = blockRenderers[node.fields.blockType] as (
            props: unknown
          ) => React.ReactNode;

          if (typeof renderer !== 'function') {
            throw new Error(
              `Missing block renderer for block type '${node.fields.blockType}'`
            );
          }

          return <React.Fragment key={index}>{renderer(node)}</React.Fragment>;
        }

        if (
          node.type === 'linebreak' ||
          node.type === 'tab' ||
          node.type === 'upload'
        ) {
          return (
            <React.Fragment key={index}>{renderElement(node)}</React.Fragment>
          );
        }

        return (
          <React.Fragment key={index}>
            {renderElement(node, serialize(node.children))}
          </React.Fragment>
        );
      }),
    [renderElement, renderText, blockRenderers]
  );

  if (!RichTextHasContent(content)) return <></>;

  return <>{serialize(content.root.children)}</>;
}

export const RichTextHasContent = (
  content?: PayloadLexicalReactRendererContent
): boolean => {
  if (!content?.root?.children) return false;

  return true;
};
